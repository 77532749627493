$primary: black;
$dark: black;
$enable-shadows: true;
$spacer: 0.5rem;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
$nav-link-color: white;
$secondary: #ffe72c;
$red: #cd1719;
$green: #84b51b;

@import "../node_modules/bootstrap/scss/bootstrap";

div.calendar-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 1rem;
    
    div.calendar-day {
        width: calc(100% / 6 - 1%);
        border: 1px solid rgba(0,0,0,.125);
        background-color: #f8f9fa;
        height: 160px;

        div.day {
            font-size: 20px;
            font-weight: bolder;
            padding: 0.5rem 0.5rem 0.5rem 0.5rem;
            background-color: rgba(0,0,0,.03);
            border-bottom: 1px solid rgba(0,0,0,.125);

            button {
                display: none;
                float: right;
                transform: scale(0.7);
                transition: transform 250ms ease-in-out;
            }

            button:hover {
                transform: scale(1);
            }
        }

        &:hover {
            div.day button {
                display: block;
            }
        }

        div.tasks {
            padding: 0.5rem;
            overflow: auto;
            height: 105px;

            span {
                margin-right: 0.3rem;
            }
        }

        &.outside {
            opacity: .3;
        }

        &.weekend {
            width: calc((100% / 6 - 1%) / 2);

            div.day {
                background-color: rgba(0,0,0,.1);
            }
        }

        &.today {
            background-color: rgba(0, 0, 0, 0.15);
        }
    }
}

div.sale-detail-columns > div {
    border-left: 1px solid #ffe72c;
}

div.sale-detail-columns > div:first-child {
    border-left: 0;
}