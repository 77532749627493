body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

input[type=checkbox], input[type=radio] {
    width: 20px;
    height: 20px;
}


.alert.flashMessage {
    position: fixed;
    bottom: 25px;
    right: 25px;
    max-width: 500px;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.2);
    animation: showFlashMessage .5s linear;
}

.alert.flashMessage.invalid {
    animation: hideFlashMessage .5s linear;
}

@keyframes showFlashMessage {
    from {
        transform: translate(200px);
        opacity: 0;
    }
    to {
        transform: translate(0);
        opacity: 1;
    }
}

@keyframes hideFlashMessage {
    from {
        transform: translate(0);
        opacity: 1;
    }
    to {
        transform: translate(200px);
        opacity: 0;
    }
}

div.sale-state-filter-block {
    border: 2px solid rgba(255, 255, 255, 0);
    padding: 5px 5px 0 5px;
    cursor: pointer;
    margin-bottom: 3px;
}

div.sale-state-filter-block:hover {
    border: 2px solid #ddd;
}

div.sale-state-filter-block.active {
    background-color: #eeffee;
}

div.sale-state-filter-color {
    display: inline-block;
    width: 50px;
    height: 25px;
    margin-right: 10px;
}

div.sale-state-filter-text {
    display: inline-block;
    position: relative;
    top: -6px;
}

div.sale-state-display {
    font-size: 60%;
}

div.sale-state-display-color {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    position: relative;
    top: 0.3rem;
    margin-right: 0.4rem;
}